const BackgroundAnimation = () => {
  return (
    <div className="w-full h-screen overflow-hidden fixed pointer-events-none">
      {Object.keys([...new Array(100)]).map((obj) => (
        <div key={obj} className="circle-container">
          <div className="circle"></div>
        </div>
      ))}
    </div>
  );
};

export default BackgroundAnimation;
