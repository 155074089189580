// import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Navbar from "../src/Components/Navbar";
import Home from "./Components/Home";
import About from "./Components/About";
import WhatIDo from "./Components/WhatIDo";
import Skills from "./Components/Skills";
import Exprience from "./Components/Experience";
import Projects from "./Components/Projects";
import Contact from "./Components/Contact";
import BackgroundAnimation from "./Components/BackgroundAnimation";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
// import { useLayoutEffect, useRef } from "react";
// import Footer from "./Components/Footer";

gsap.registerPlugin(ScrollTrigger);

const App = () => {
  // const ctx = useRef<any | null>(null);
  // const sectionRef = useRef<null>(null);
  // useLayoutEffect(() => {
  //   ctx.current = gsap.context(() => {
  //     const tl = gsap.timeline({
  //       scrollTrigger: {
  //         trigger: sectionRef.current,
  //         start: "top top",
  //         end: "bottom bottom",
  //         scrub: true,
  //         markers: true,
  //       },
  //     });
  //   }, sectionRef);

  //   return () => ctx.current.revert(); // Cleanup GSAP animations
  // }, []);
  return (
    // <div ref={sectionRef}>
    <div>
      <BackgroundAnimation />
      <Navbar />
      <Home />
      {/* <RouterProvider router={routers} /> */}
      <About />
      <WhatIDo />
      {/* <div className='md:flex md:flex-row  w-[100%] h-100vh'> */}
      <Skills />
      <Exprience />
      {/* </div> */}
      <Projects />
      <Contact />
    </div>
  );
};

export default App;
